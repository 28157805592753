import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/commons.scss'                                             
// 引入适配
// import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export

// 引入全局css样式
import './assets/css/common.css'
import './assets/css/global.css'                                             

// 引入请求管理,把请求挂载在原型上面
import http from './utils/https'
Vue.prototype.$http = http



// 引入vue-meta,vue-meta实现router动态设置meta标签
import  Meta from 'vue-meta';
Vue.use(Meta);

// 正则域名,IP格式 , Base64 ,消息提示
import test from './mixins/mixin'
Vue.mixin(test)
// Vue.prototype.$test = test

// 加载圈
import Overlay from '@/component/overlay.vue'
Vue.component('Overlay',Overlay)
// 操作确认
import Operate from '@/component/operate.vue'
Vue.component('Operate',Operate)

// 关闭生产提示
Vue.config.productionTip = false


// 创建vue实例,这里面就是VM
new Vue({
    router,
    store,
    vuetify,
    beforeCreate(){
        Vue.prototype.$url_ip=this
    },
    metaInfo(){
        return {
            title: store.state.metaInfo.title,
            meta: [
                {
                    name:"keywords",
                    content: "稳网互联,边缘分析,测绘分类,网站分类,网络空间测绘,网络空间,态势感知,网络安全,搜索引擎,全网搜索,端口扫描,空间测绘,安全大数据"
                },{
                    name:"description",
                    content: "稳网互联,边缘分析,测绘分类,网站分类,网络空间测绘,网络空间,态势感知,网络安全,搜索引擎,全网搜索,端口扫描,空间测绘,安全大数据"
                }
            ]
        }
    },
    render: h => h(App)
}).$mount('#app')