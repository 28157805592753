<template>
    <v-app  :class="Bg"         >

        <navs  :bool="$route.meta.showSearch" v-if="$route.meta.showHeader" class="headers"> </navs>
        <v-main   >
            <!-- min-width:1200px -->
            <div id="app" style="height:100%;" > 
                <Snackbar />

                <router-view v-if="isRouterAlive"></router-view>
            </div>
        </v-main>

        <!--  v-if="$route.meta.requireAuth == true || $route.meta.requireAuth == undefined" -->
        <v-footer class="d-flex justify-center align-center grey--text lighten-1 mouse"
            style="background: rgba(7, 8, 50, 0) !important;;color: #137DB9 !important;height:50px;"  >
            <v-img  @click="goUrl" :src="require('./assets/images/salute.png')" max-width="20" height="20" class="mr-2"></v-img>
            <span  @click="goUrl" >中华人民共和国信息产业部备案：豫ICP备05024996号 </span>
        </v-footer>

        <v-overlay :value="overlay" opacity="1">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-app>
</template>
<script>
// import Snackbar from '@/component/snackbar.vue';
const Snackbar = ()=>import('@/component/snackbar.vue')
const navs = ()=>import('@/component/nav.vue');
export default {
    globalData: {
        show: false
    },
    components: {
        navs,
        Snackbar
    },

    provide() {
        return {
            reload: this.reload,
        };
    },
    
    data() {
        return {
            alert:true,
            isRouterAlive: true,
            overlay:false,
            Bg:'searchBg',
			
        };
    },
    created(){
        // this.preloadImage(require('./assets/images/searchBg.jpg')).finally(()=>{
        //     this.overlay = false
        // })

    },

    watch:{
        '$route'(news,oldes){
            setTimeout(()=>{
                this.$store.commit("snackbar/CLOSE_SNACKBAR")
            },1000)
            // console.log(news.path)
            if(news.path == '/login'){
                this.Bg = 'loginBg'
            }else if(news.path == '/register'){
                this.Bg = 'registerBg'
            }else{
                this.Bg = 'searchBg'
            }
			

        },
        
    },
    methods: {
        goUrl(){
            window.open("https://beian.miit.gov.cn/");
        },
        // 通过 $nextTick()，协助实现。先把 <router-view /> 移除，移除后再重新添加，达到刷新当前页面的功能
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
    },

};
</script>

<style lang="scss" scoped>

// 输入框  占位符的字体样式
::v-deep .theme--light.v-input input::placeholder {
    color: #C0CBD1;
}

.alerts{
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
}
.headers{
	background: #0e1b26 !important;
    z-index: 99;
}
.searchBg{
    background:  #08121A !important;
    width: 100%;
}
.loginBg{
    background: linear-gradient(180deg, #021E2C 0%, #011924 100%) !important;
}
.registerBg{
    background: #F1F4F6 !important;
}
// -- 分页 --
::v-deep .theme--light.v-pagination .v-pagination__item{
    background: transparent ;
    color:#FFFFFF;
    font-size:14px !important;
    box-shadow: none;
}
::v-deep .theme--light.v-pagination .v-pagination__navigation{
    // background: #011924 !important;
    background:  #08121A !important;
    border:2px solid #06A5F6 !important;
    border-radius: 50%;
    color:#06A5F6 !important;
}
::v-deep .v-pagination__more{
    font-size:14px !important;
    color:#FFFFFF;
}
::v-deep .v-pagination__navigation .v-icon{
    color:#06A5F6 !important;
}
::v-deep .theme--light.v-pagination .v-pagination__item--active{
    background: #06A5F6 !important;
    border-radius: 50%;
}
// 
</style>
