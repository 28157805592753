<template>
        <!-- persistent -->
    <!--  -->
    <v-dialog
        v-model="dialog"
        width="588"
        @click:outside="$emit('cancel',false)"
    >
        <v-card color="#082737">
            <v-card-title class="d-flex justify-space-between py-5 px-6" style="border-bottom: 1px solid #011924;">
                <div class="f18 fw5 c06">
                    {{ title!=undefined ? title : '操作确认' }}
                </div>
                <v-img :src="require('@/assets/images/closeX.png')" max-width="24" height="24" class="mouse" @click="$emit('cancel',false)"></v-img>
            </v-card-title>
            <slot>
                <v-card-text class="pt-10 pb-16 cf f16 fw4 text-center">
                    {{ details }}  
                </v-card-text>
            </slot>
            <v-card-actions class="justify-end pb-6" v-if="isBtn==undefined">
                <v-btn
                    color="#073E5A"
                    rounded 
                    depressed
                    height="38"
                    width="80"
                    style="color: #07A5F6 ;"
                    class="f16 fw4"
                    @click="$emit('cancel',false)"
                >
                    取消
                </v-btn>
                <v-btn
                    color="#07A5F6"
                    rounded 
                    depressed
                    height="38"
                    width="80"
                    style="color: #07A5F6 ;"
                    class="f16 fw4 cf ml-6"
                    :loading="load"
                    @click="$emit('confirm')"
                >
                    确定
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
</template>
<script>

export default {
    
    data() {
        return {
            dialog:this.show
        };
    },
    props:{
        show:Boolean,
        title:String,
        details:String,
        load:Boolean,
        isBtn:String
    },
    watch:{
        show(news,olds){
            // console.log('news',news)
            this.dialog = news
        }
    }
    
};
</script>

<style lang='scss' scoped>

</style>