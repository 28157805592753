// 导入axios
import axios from 'axios'
import store from '../store/index'
const http = axios.create({
    baseURL: 'https://www.rimdeep.com/flows',
    timeout: 100000
})

// 请求拦截器,这里可以用||连接起来,写进去的是不用携带token就可以获取的数据
http.interceptors.request.use(
    (config) => {
        // tokens登录token pwdToken 临时token	
        let tokens = store.state.token
        let pwdToken= window.localStorage.getItem('token')
        // let pwdToken= 'azlhZ2ZRclNyQ2Mvd0pHVGZkNWxsdz09'
        // 请求头传  字段version 
		// v5正式  v6 测试
        config.headers = { 
            "version": "v5",// 正式
            "token": tokens =='' ? pwdToken : tokens
         }
        //  
        if(config.url == "/img_upload" || config.url == "/img_uploads" || config.url == "/batch_txt"){
            config.headers = { "Content-Type": "multipart/form-data"}
            return config
        }
        // console.log(config)
        if(config.method=='get'){
            config.params={
                token: tokens != '' ? tokens : pwdToken,
                ...config.params
            }
            return config
        }
       
        config.data = {
            token: tokens != '' ? tokens : pwdToken,
            ...config.data
        }
        return config
    }
)


// 响应拦截器
http.interceptors.response.use((res) => {
    if (res.data.code == 200) {
        // 返回数据,关闭弹窗
        return res.data
    }
    return res.data
}, (error) => {
    console.log(error);

})
export default http