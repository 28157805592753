import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'
import snackbar from "./modules/snackbar";
// 引入vuex
Vue.use(Vuex)
// dispatch commit 都是store里面的方法,所以要经过store管理
//  
export default new Vuex.Store({
  state: {
    // 显示会员注册
    show:false,
    // 登录存储的token
    token:'',
    type:'' , //特定的用户
    // 历史记录
    history:[],
    // 登录过期时间
    times:'',
    // 动态meta标签
    metaInfo: {
      title: "RimDeep网络空间测绘 — 边缘分析,测绘分类",
      keywords:" 稳网互联,边缘分析,测绘分类,网站分类,,网络空间测绘,网络空间,态势感知,网络安全,搜索引擎,全网搜索,端口扫描,空间测绘,安全大数据",
      description:"稳网互联,边缘分析,测绘分类,网站分类,,网络空间测绘,网络空间,态势感知,网络安全,搜索引擎,全网搜索,端口扫描,空间测绘,安全大数据"
    },
    // meta标题前的域名或IP
    domain_ip:'',
    // nav 选中项
    navTab:-1,
    // 导航栏值
    navVal:'',
    // 我的下的tab
    tabIndex:0,

  },
  getters: {

  },
  mutations: {
    changeTabIndex(state,tabIndex){
        state.tabIndex = tabIndex
    },
    // 
    setNavVal(state,navVal){
        state.navVal = navVal
    },
    setNavTab(state,navTab){
        state.navTab=navTab
    },
    // meta标题前的域名或IP
    domain_ip(state,domain_ip){
      state.domain_ip=domain_ip
    },
    // 动态修改meta标签
    meta(state,metaInfo){
      state.metaInfo=metaInfo
    },
    // 登录过期时间 
    times(state,times){
      state.times=times
    },
    // 历史记录
    history(state,history){
      state.history=history
    },
    //特定的用户
    type(state,type){
      state.type=type
    },
    // 登录token
    token(state,token){
      state.token=token
    },
    show(state,show){
      state.show=show
    },
    clear(state){
      delete state.times
      delete state.token
      delete state.nackName
    },
  },
  actions: {

  },
  modules: {
    snackbar
  },
  plugins:[createVuexAlong()]
})

































