import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
// import mixin from '@/mixins/mixin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      name:'search',
      component:()=>import(/* webpackChunkName: "search" */ '@/views/search.vue'),
      prefetch: true,
      meta:{
        metaInfo:{
          title:"RimDeep网络空间测绘 — 边缘分析,测绘分类",
        },
        background:2,
        // 输入框是否显示
        showSearch:false,
        // 导航栏是否显示
        showHeader:true
        // showHeader:false
      }
    },
    {
      path: '/detail',
      name: 'detail',
      component:()=>import(/* webpackChunkName: "detail" */ '@/views/detail.vue'),
      meta:{
        metaInfo:{
          title:`_RimDeep搜索网络空间测绘 — 边缘分析，测绘分类`
        },
        // 输入框是否显示
        showSearch:true,
        // 导航栏是否显示
        showHeader:true,
      }
    },
    {
      path: '/login',
      name: 'login',
      component:()=>import(/* webpackChunkName: "login" */ '@/views/login.vue'),
      meta:{
        showHeader:false,
        background:2
      }
    },
    {
      path: '/register',
      name: 'register',
      component:()=>import(/* webpackChunkName: "register" */ '@/views/register.vue'),
      meta:{
        showHeader:false,
      }
    },
    {
      path: '/api',
      name: 'api',
      component:()=>import(/* webpackChunkName: "api" */ '@/views/api.vue'),
      meta:{
        requireAuth:false,
        showHeader:true,
        // 输入框是否显示
        showSearch:true,
      },
      
    },
    {
      path: '/my',
      name: 'my',
      component:()=>import(/* webpackChunkName: "my" */ '@/views/my.vue'),
      meta:{
        requireAuth:true,
        showHeader:true,
        // 输入框是否显示
        showSearch:true,
      }
    },
    {
      path: '/asn',
      name: 'asn',
      component:()=>import(/* webpackChunkName: "asn" */ '@/views/asn.vue'),
      meta:{
        requireAuth:true,
        showHeader:true,
        // 输入框是否显示
        showSearch:true,
      }
    },
    {
      path: '/member',
      name: 'member',
      component:()=>import(/* webpackChunkName: "member" */ '@/views/member.vue'),
      meta:{
        requireAuth:false,
        showHeader:true,
        // 输入框是否显示
        showSearch:true,
      }
    },
	{
      path: '/vipMember',
      name: 'vipMember',
      component:()=>import(/* webpackChunkName: "member" */ '@/views/vipMember.vue'),
      meta:{
        requireAuth:false,
        showHeader:true,
        // 输入框是否显示
        showSearch:true,
      }
    },
    {
        path: '/report',
        name: 'report',
        component:()=>import(/* webpackChunkName: "report" */ '@/views/report.vue'),
        meta:{
          requireAuth:true,
          showHeader:true,
          // 输入框是否显示
          showSearch:true,
        }
    },
    {
        path: '/batchQuery',
        name: 'batchQuery',
        component:()=>import(/* webpackChunkName: "batchQuery" */ '@/views/batchQuery.vue'),
        meta:{
          requireAuth:true,
          showHeader:true,
          // 输入框是否显示
          showSearch:true,
        }
    },
    {
        path: '/article',
        name: 'article',
        component:()=>import(/* webpackChunkName: "article" */ '@/views/article.vue'),
        meta:{
          requireAuth:false,
          showHeader:true,
          // 输入框是否显示
          showSearch:true,
        }
    },
    {
      path:'*',
      component:()=>import(/* webpackChunkName: "notFind" */ '@/views/notFind.vue'),
      meta:{showHeader:true}
      // redirect:'/404'
    }

  ]

})

// 跳转相同路由,页面不报错
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
 


// 挂载路由导航守卫
// let isShow=false
router.beforeEach(async (to, from, next) => {

    const urlName = to.name

    const tool = ['asn','report','api']

    if(urlName=='member'){
        store.commit('setNavTab',0)
    }else if(tool.indexOf(urlName)!=-1){
        store.commit('setNavTab',1)
    }else if(urlName=='my'){
        store.commit('setNavTab',2)
    }else if(urlName=='article'){
        store.commit('setNavTab',3)
    }else{
        store.commit('setNavTab',-1)
    }

  
  let times=store.state.times
  // console.log('过期时间',times);
  let dates1 = +new Date();//时间戳
  // console.log('当前时间',dates1);
  // dates1当前时间 > times过期时间  说明token失效
  if(dates1>times){

    store.commit('token','')
    // 调用会员信息的方法 判断是否登录过期
    // if(!isShow){
    //   mixin.methods.message('warning','请重新登陆!')
    //   isShow = true
    // }

  }
//   else{
//     isShow = false
//   }

    // 会员信息  获取成功  code为200  否则201
    let code = ''
    const token = store.state.token
    if(token==''){
        code = 201
    }else{
        code = 200
    }

  // 判断登录后   登录,注册路由不可用
  if(code==200 && to.path=='/login'){
    next('/')
  }else if(code==200 && to.path=='/register'){
    next('/')
  }else{
    next()
  }
  /*修改首页,详情页的meta标签,如果需要登录后才能跳转,那就是下一步了,
  首先metaInfo要有值,自己定义, 然后分开判断*/
  if (to.meta.metaInfo!=''){
    if(to.path=='/detail'){
      let url_ip=to.query.url
      // let url_ip=mixin.methods.Base(2,to.query.url)
      // 每次搜索域名或IP 修改meta标签的标题
      let aaa = {
        title:`${url_ip}_RimDeep搜索网络空间测绘 — 边缘分析，测绘分类`
      }
      store.commit("meta", aaa)
    }else{
      store.commit("meta", { title:"RimDeep搜索网络空间测绘 — 边缘分析，测绘分类"})
    }
    next()
  }
  /* requireAuth为true  自己设置的 
    登录后才能进入,否则去登陆
  */
  if (to.meta.requireAuth ) {
    // code为200 点击到所在的页面 
    if(code==200){ 
      next()
    } else{
        // mixin.methods.message('warning','请登陆后查看!')
        next('/login')
    }
    
  } else{
    next()
  }
})

export default router