let Base64 = require("js-base64").Base64;
import store from '@/store'
export default {

    data() {
        return {
            property: 'woshimixin',
        };
    },
    methods: {
        /*
        author fanfan
        时间2022、9、14
        校验输入的内容为IP或者域名
        @param query 域名或者IP
        @param isBase64 bool
        @return 正确格式  param base64和 search domain:"rimdeep.com"如此格式的 text = {'search':'','param':'','query':'','queryBase':''}  {带前缀的域名或IP,加密的带前缀的域名或IP,域名或IP,加密的域名或IP}
        @return isIp 是否为ip 
        
        */
        isDomainOrIp(query, isBase64) {
            let text = {
                'search': '',
                'param': '',
                'query': '',
                'queryBase': '',
                'isIp': ''
            }
            // console.log(query, isBase64)
            if (isBase64 == true) {
                query = Base64.decode(query)
            }
            let ipTest = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            let domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,})$/;
            // 域名部分
            query = query.replace(/https?:\/\/(.*?)(\/.*)/, "$1")
            // console.log("query",query)
            // 去除 特殊字符
            let reg = /[^a-zA-Z0-9.=|&/]/g; 
            query = query.replace(reg, ''); 
            reg = new RegExp('ip=', "g");
            query = query.replace(reg, "");
            reg = new RegExp('domain=', "g");
            query = query.replace(reg, "");

            // 定义返回参数
            if (ipTest.test(query) == true) {
                text.isIp = 'ip'
                let ce = `ip="${query}"`;
                text.search = ce.replace(/\s+/g, "")
                text.param = Base64.encode(text.search);
                text.query = query
                text.queryBase = Base64.encode(text.query)
                // console.log('aaaa',Base64.decode(text.param))
                // console.log('bbb',text.search);
            } else if(domainRegex.test(query)==true) {
                text.isIp = 'domain'
                let ce = `domain="${query}"`;
                text.search = ce.replace(/\s+/g, "")
                text.param = Base64.encode(text.search);
                text.query = query
                text.queryBase = Base64.encode(text.query)
                // console.log(Base64.decode(text.param))
                // console.log(text.search);

            }else{
                if(query.includes('title=') || query.includes('body=')){
                    // console.log('*****关键字')
                    text.isIp = 'keyword'
                    
                }else if(query.includes('ico=')){
                    // console.log('*****ico')
                    text.isIp = 'ico'

                }else if(query.includes('rid=')){
                    // console.log('*****ico')
                    text.isIp = 'rid'
                }else if(query.includes('app=')){
                    // console.log('*****ico')
                    text.isIp = 'app'
                }else{
                    text.isIp = 'error'
                }
                text.search = query
                text.param = Base64.encode(text.search);
                text.queryBase = Base64.encode(text.search);
            }
            // console.log(text.isIp)
            return text;
        },
        /*
          wff 2022.9.17
          用于消息提示
          showClose  是否可关闭
          message  提示内容
          type  类型  如:success warning
          offset  距离顶部的距离
        */
        message(type, message) {
            var color ;
            if(type=='success'){
                color = '#4CAF50'
            }else if(type=='warning'){
                color = '#FB8C00'
            }else if(type=='error'){
                color = '#FF5252'
            }
            // console.log('1231')
            store.dispatch('snackbar/openSnackbar', {
                msg: message,
                color
            })
            
        },
        /*
          1加密 2解密
        */
        Base(type, val) {
            if (type == 1) {
                let cc = Base64.encode(val)
                return cc
            } else {
                let bb = Base64.decode(val)
                console.log(bb);
                return bb
            }

        },
        /* 1.0  gfc  
        存储本地数据,进行搜索的时候带调用
        @params  value就是地址栏输入的参数
        注意a里面的值分开写,但是不能接收
        */
        setHistory(value) {
            // 最多只能存十条数据,相同数据不计入
            if (window.localStorage.getItem('history')) {
                var a = JSON.parse(window.localStorage.getItem('history'))
                var repetition = a.every(item => item != value)

                if (repetition) {
                    a.unshift(value)
                }
                let bb = a.slice(0, 5)
                window.localStorage.setItem('history', JSON.stringify(bb))
            } else {
                var arr = [value]
                window.localStorage.setItem('history', JSON.stringify(arr))
            }
        },
        /* 1.0  gfc  
        校验输入的域名
        */
        checkDomain(dom) {
            return RegExp(
                    /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
                ).test(dom);
        },
        /* 1.0  gfc  
        // 校验输入的ip
        */
        checkIp(ips) {
            return RegExp(
                    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/).test(ips);
        },
        // 图片预加载
        preloadImage(imageUrl) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageUrl;
                img.onload = () => {
                    resolve(img);
                };

                img.onerror = (error) => {
                    reject(error);
                };

                
            });
        },

    },
}