<template>
    <div>
        <v-overlay :absolute="true" :value="loadings" color="#08121a" :opacity="opacity" :z-index="33">
            <v-progress-circular indeterminate size="34" color="light-blue darken-1">
            </v-progress-circular>
            <div class="
                d-flex
                justify-center
                align-center
                blue--text
                text--lighten-1
                ">
                加载中...
            </div>
        </v-overlay>
    </div>
</template>

<script>
export default {
    name: 'RimdeepComOverlay',
    props: {
        'loading' : Boolean,
        'opacity' : {
            type:Number,
            default : .8
        }

    },
    data() {
        return {
            loadings: this.loading,
        }
    },

    mounted() {

    },
    watch: {
        loading() {
            this.loadings = !this.loadings
        }
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.basic {
    height: 24px;
}
</style>